import { Stack, Typography } from "@mui/material";
import { SignInButton } from "../components/SignInButton";

export const ExternalUserErrorPage = () => {
  return (
    <Stack
      direction={"column"}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={2}
    >
      <Typography variant="h4">Access denied</Typography>
      <Typography>
        {
          "The supplied Vestas email does not have access to this application, try using an external account"
        }
      </Typography>
      <SignInButton />
    </Stack>
  );
};
