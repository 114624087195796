import { useMsal } from "@azure/msal-react";
import Form from "react-bootstrap/Form";
import { Alert, Snackbar, Typography } from "@mui/material";
import { useSendSupportTicket } from "../apis/support";
import "../styles/Support.css";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Modal from "react-bootstrap/esm/Modal";
import "../styles/Support.css";
import { useEffect, useState } from "react";
import { LoadSpinner } from "./LoadSpinner";
import { Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useGetUsername } from "../apis/authConfig";

export const Support = () => {
  const userName = useGetUsername();
  const { accounts } = useMsal();
  const [searchParams] = useSearchParams();
  const [topic, setTopic] = useState("");
  const [message, setMessage] = useState("");
  const {
    mutate: sendTicket,
    isLoading,
    isSuccess,
    status,
  } = useSendSupportTicket();

  const SendTicket = () => {
    if (topic.length !== 0 && message.length !== 0) {
      sendTicket({ topic, message });
    }
  };

  useEffect(() => {
    console.log(status);
    if (!isSuccess) {
      setTopic("");
      setMessage("");
    }
  }, [isSuccess, status]);

  return (
    <>
      <br />
      <Form>
        <Typography variant="h5" align="center" style={{ marginTop: "30px" }}>
          Support
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          style={{ marginTop: "5px", marginBottom: "50px", color: "#555" }}
        >
          Describe what you need help with
        </Typography>
        <Row>
          <Col></Col>
          <Col xs={5}>
            <Form.Group className="Form-label-align">
              <Form.Label>Topic</Form.Label>
              <Form.Control
                placeholder=""
                maxLength={150}
                value={topic}
                onChange={(event) => setTopic(event.target.value)}
              />
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col xs={5}>
            <Form.Group className="Form-label-align">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                className="Message-textbox"
                rows={6}
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>
        <Button
          variant="contained"
          onClick={() => SendTicket()}
          disabled={topic.length === 0 || message.length === 0}
          sx={{ mt: 2 }}
        >
          Send
        </Button>
        <Modal
          show={isSuccess}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body
            style={{
              textAlign: "center",
              backgroundColor: "rgba(255,255,255,1)",
              borderRadius: "0.3rem",
            }}
          >
            <h3>Message is sent</h3>
            <div style={{ width: "20vw", display: "inline-block" }}>
              You will hear from us on your mail {accounts[0]?.username} as soon
              as possible.
            </div>
            <br />
            <br />
            <Button href="/">Close</Button>
          </Modal.Body>
        </Modal>
      </Form>
      {isLoading && <LoadSpinner />}
    </>
  );
};
