import { useMutation } from "react-query";

interface Props {
  topic: string;
  message: string;
}

export const useSendNewUserTicket = () => {
  const api = process.env.REACT_APP_API_B2C_BASE_URL;

  return useMutation(async ({ topic, message }: Props) => {
    // Silently acquires an access token which is then attached to a request for MS Graph data

    const headers = new Headers();
    headers.append("access-control-allow-origin", "*");
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        Topic: topic,
        Message: message,
      }),
    };

    return fetch(api + "/support/v1/SendSupportTicket", options).then((res) =>
      res.json()
    );
  });
};
