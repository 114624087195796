import { useQuery } from "react-query";
import { Video } from "../models/video";
import { useGetApi, useGetToken } from "./authConfig";

export const useVideos = () => {
  const tokenPromise = useGetToken();
  const api = useGetApi();

  return useQuery<Video[], unknown, Video[]>("videos", async () => {
    const token = await tokenPromise;

    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("access-control-allow-origin", "*");

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(`${api}/documents/v1/GetVideos`, options).then((res) =>
      res.json()
    );
  });
};
