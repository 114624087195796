import { useLocation } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { Link as RouterLink } from "react-router-dom";
import caretImg from "../images/caret-right.svg";
import { useEffect } from "react";
import { GetDocumentsByDMSNumberDocument } from "../apis/lookup";
import {
  generateTDocBlobFilename,
  isExcelFile,
  isVideoFile,
} from "../helpers/utility";

interface LocationState {
  documents: GetDocumentsByDMSNumberDocument[];
  mode: "DMS" | "Material";
}

const getLinkToViewer = (document: GetDocumentsByDMSNumberDocument) => {
  if (isVideoFile(document.documentFormat))
    return `/videoPlayerTdoc/${generateTDocBlobFilename(document)}`;

  return "/pdfviewer";
};

export const MutltiResult = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const documents = state.documents;
  const mode = state.mode;

  return (
    <ListGroup className="docsList">
      <Row key={"header"}>
        <Col></Col>
        <Col xs={8}>
          <div className="cols header">
            <span style={{ width: "80%" }}> DMS Number - Document Name </span>
            <span style={{ width: "20%" }}> Language </span>
          </div>
        </Col>
        <Col></Col>
      </Row>
      {documents.map((document: GetDocumentsByDMSNumberDocument) => (
        <Row key={document.documentId}>
          <Col></Col>
          <Col xs={8}>
            {isExcelFile(document.documentFormat) ? (
              <a
                href={document.documentData}
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <DocumentItem document={document} />
              </a>
            ) : (
              <RouterLink
                to={getLinkToViewer(document)}
                state={{
                  documents: documents.filter(
                    (doc) => doc.documentId === document.documentId
                  ),
                }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <DocumentItem document={document} />
              </RouterLink>
            )}
          </Col>
          <Col></Col>
        </Row>
      ))}
    </ListGroup>
  );
};

type DocumentItemProps = {
  document: GetDocumentsByDMSNumberDocument;
};

const DocumentItem = ({ document }: DocumentItemProps) => {
  return (
    <ListGroup.Item action>
      <div className="cols">
        <span style={{ width: "80%" }}>
          {" "}
          {document.dmsNumber} - {document.documentName}{" "}
        </span>
        <span style={{ width: "20%" }}>
          <div
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              marginRight: "20px",
              float: "left",
            }}
          >
            {document.language}
          </div>
          <div
            style={{
              float: "right",
              display: "inline-block",
              verticalAlign: "middle",
            }}
          >
            <img src={caretImg} />
          </div>
        </span>
      </div>
    </ListGroup.Item>
  );
};
