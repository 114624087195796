import { useGetApi, useGetIfVestasMail, useGetToken } from "./authConfig";
import {
  useQuery,
  UseQueryResult,
  useMutation,
  useQueryClient,
} from "react-query";
import {
  IUserViewAccess,
  IUserHasGrantedPermission,
  IUserDocumentPermissions,
} from "../models/user-management";

export const useB2CUserHasGrantedPermission =
  (): UseQueryResult<IUserHasGrantedPermission> => {
    const tokenPromise = useGetToken();
    const isVestasMail = useGetIfVestasMail();
    const api = useGetApi();

    return useQuery(
      ["useB2CUserHasGrantedPermission"],
      async () => {
        const token = await tokenPromise;
        const headers = new Headers();
        headers.append("Authorization", token);
        headers.append("access-control-allow-origin", "*");

        const options = {
          method: "GET",
          headers: headers,
        };

        return fetch(`${api}/user-management/v1/permission`, options).then(
          (res) => res.json()
        );
      },
      {
        enabled: !isVestasMail,
      }
    );
  };

export const useB2CUserViewAccess = (
  validUser: boolean
): UseQueryResult<IUserViewAccess> => {
  const tokenPromise = useGetToken();
  const isVestasMail = useGetIfVestasMail();
  const api = useGetApi();

  return useQuery(
    ["useB2CUserViewAccess"],
    async () => {
      const token = await tokenPromise;
      const headers = new Headers();
      headers.append("Authorization", token);
      headers.append("access-control-allow-origin", "*");

      const options = {
        method: "GET",
        headers: headers,
      };

      return fetch(`${api}/user-management/v1/permission/access`, options).then(
        (res) => res.json()
      );
    },
    {
      enabled: validUser && !isVestasMail,
    }
  );
};

export const useB2CUserDocumentPermissions =
  (): UseQueryResult<IUserDocumentPermissions> => {
    const tokenPromise = useGetToken();
    const isVestasMail = useGetIfVestasMail();
    const api = useGetApi();

    return useQuery(
      ["useB2CUserDocumentPermissions"],
      async () => {
        const token = await tokenPromise;
        const headers = new Headers();
        headers.append("Authorization", token);
        headers.append("access-control-allow-origin", "*");

        const options = {
          method: "GET",
          headers: headers,
        };

        return fetch(
          `${api}/user-management/v1/permission/document`,
          options
        ).then((res) => res.json());
      },
      {
        enabled: !isVestasMail,
      }
    );
  };

export const useAcceptB2CTermsOfUse = () => {
  const queryClient = useQueryClient();
  const tokenPromise = useGetToken();
  const api = useGetApi();

  return useMutation(
    async () => {
      const token = await tokenPromise;
      const headers = new Headers();
      headers.append("Authorization", token);
      headers.append("access-control-allow-origin", "*");

      const options = {
        method: "POST",
        headers: headers,
      };

      return fetch(`${api}/user-management/v1/permission/grant`, options).then(
        (res) => res.json()
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "useB2CUserHasGrantedPermission",
          "useB2CUserDocumentPermissions",
          "useB2CUserDocumentPermissions",
        ]);
      },
    }
  );
};
