import { useState, useMemo } from "react";
import { LoadSpinner } from "./LoadSpinner";
import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "../styles/Tools.scss";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";
import { useNavigate } from "react-router";
import { useSearch } from "../apis/tools";
import { useLocation } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import caretImg from "../images/caret-right.svg";

export const ToolsResult = () => {
  const location = useLocation();
  const state: any = location.state;
  const certificates = state.certificates;

  return (
    <ListGroup className="docsList">
      {certificates.map((certificate: any) => (
        <Row key={certificate.doknr}>
          <Col></Col>
          <Col xs={8}>
            <Link
              to="/toolcertificateviewer"
              state={{
                url: certificate.dmsurl,
              }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <ListGroup.Item action>
                {certificate.dktxt} - {certificate.dokar} - {certificate.doknr}{" "}
                - {certificate.dokvr} - {certificate.doktl}
                <span className="float-right">
                  <div
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                  >
                    <img src={caretImg}></img>
                  </div>
                </span>
              </ListGroup.Item>
            </Link>
          </Col>
          <Col></Col>
        </Row>
      ))}
    </ListGroup>
  );
};
