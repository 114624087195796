import { useLocation } from "react-router-dom";
import { GetDocumentsByDMSNumberDocument } from "../apis/lookup";

export const TDocVideoPlayer = () => {
  const location = useLocation();
  const stateData = (location.state as any)
    .documents[0] as GetDocumentsByDMSNumberDocument;

  if (stateData === undefined || !stateData.documentData) {
    return <h2>Video does not exist</h2>;
  }

  return (
    <>
      {stateData?.documentData !== undefined && (
        <>
          <h2>{stateData.documentName ?? ""}</h2>
          <video width="640" controls autoPlay={true}>
            <source src={stateData.documentData} />
          </video>
        </>
      )}
    </>
  );
};
