import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Button } from "@mui/material";

interface Props {
  pdfInstance: any;
}

export const ExportBtn = ({ pdfInstance }: Props) => {
  return (
    <Button
      variant="outlined"
      className="favorite-btn-size"
      onClick={() => {
        pdfInstance.UI.downloadPdf({ filename: "certificate.pdf" });
      }}
      startIcon={<FileDownloadOutlinedIcon />}
      sx={{
        "margin-top": "20px",
        "margin-right": "5px",
        "background-color": "#FFFFFF",
        color: "black",
        "border-color": "#FFFFFF",
      }}
    >
      Export
    </Button>
  );
};
