import { useState } from "react";
import { useEffect } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import caretImg from "../images/caret-right.svg";
import "../styles/Videos.css";
import { LoadSpinner } from "./LoadSpinner";
import { useGetPreferredLanguage } from "../apis/preferredLanguage";
import { useNavigate } from "react-router";
import {
  generateTDocBlobFilename,
  isExcelFile,
  isVideoFile,
  sortTurbines,
} from "../helpers/utility";
import { useGetItems } from "../apis/useDocSelect";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import "../styles/docList.scss";
import { useB2CUserViewAccess } from "../apis/userManagement";
import { useGetIfVestasMail } from "../apis/authConfig";
import { AddError } from "../apis/error";
import { useMediaQuery, useTheme } from "@mui/material";

export interface FilterState {
  url: string;
  filters: {
    Level: number;
    Area: string | null;
    RotorSize_MWDisplay: string | null;
    MkMajor_MkMinor: string | null;
    Freq: string | null;
    CategoryName: string | null;
    SubCategoryName: string | null;
    ItemName: string | null;
    Language: string[] | null;
  };
}

type Document = {
  createdOn: string;
  dmsNumber: string;
  docDescription: string;
  docGUID: string;
  documentData: string;
  documentFormat: string;
  documentId: number;
  documentName: string;
  documentType: string;
  isDeleted: boolean;
  language: string;
  lastUpdatedOn: string;
  lastUpdatedOnFromSAP: string;
  masterDocumentDmsNumber: string | null;
  metaData: any[];
  metaDataCategories: null;
  size: number;
  subDocumentId: string;
  translations: null;
  turbines: any[];
  version: string;
};

interface Props {
  area?: string;
}

export const DocSelect = ({ area }: Props) => {
  const [state, setState] = useState<FilterState>({
    url: "",
    filters: {
      Level: 0,
      Area: area || null,
      RotorSize_MWDisplay: null,
      MkMajor_MkMinor: null,
      Freq: null,
      CategoryName: null,
      SubCategoryName: null,
      ItemName: null,
      Language: null,
    },
  });
  const [doRefresh, setDoRefresh] = useState<boolean>();
  const isVestasMail = useGetIfVestasMail();
  const [items, setItems] = useState<{ data: string[] }>({ data: [] });
  const [documents, setDocuments] = useState<Document[] | null>(null);
  const [searchParams] = useSearchParams();
  const { isLoading: isLoadingItems, data } = useGetItems(state.filters);
  const { data: preferredLanguage, isLoading: isLoadingPreferredLanguage } =
    useGetPreferredLanguage();

  const {
    data: b2cUserViewAccessData,
    status: b2cUserViewAccessStatus,
    isLoading: isb2cUserViewAccessLoading,
  } = useB2CUserViewAccess(true);

  const navigate = useNavigate();

  const theme = useTheme();
  const matchesThemeMd = useMediaQuery(theme.breakpoints.down("md"));

  const isLoading = isLoadingPreferredLanguage || isLoadingItems;

  const GetNextItems = (item: string) => {
    SetNextState();
    console.log(state.filters.Level);

    switch (state.filters.Level) {
      case 2:
        state.filters.RotorSize_MWDisplay = item;
        state.url =
          state.url +
          "&rotorSize=" +
          encodeURIComponent(state.filters.RotorSize_MWDisplay ?? "");
        break;
      case 3:
        state.filters.MkMajor_MkMinor = item;
        state.url =
          state.url +
          "&mkMajorMkMinor=" +
          encodeURIComponent(state.filters.MkMajor_MkMinor ?? "");
        break;
      case 4:
        state.filters.Freq = item;
        state.url =
          state.url + "&freq=" + encodeURIComponent(state.filters.Freq ?? "");
        break;
      case 5:
        state.filters.CategoryName = item;
        state.url =
          state.url +
          "&category=" +
          encodeURIComponent(state.filters.CategoryName ?? "");
        break;
      case 6:
        state.filters.SubCategoryName = item;
        state.url =
          state.url +
          "&subCategory=" +
          encodeURIComponent(state.filters.SubCategoryName ?? "");
        break;
      case 7:
        state.filters.ItemName = item;
        state.filters.Language = preferredLanguage?.language
          ? ["ENG", preferredLanguage.language]
          : ["ENG"];
        state.url =
          state.url +
          "&itemName=" +
          encodeURIComponent(state.filters.ItemName ?? "");
        state.url =
          state.url + "&languages=" + JSON.stringify(state.filters.Language);
        break;
    }

    navigate(state.url);
  };

  const ChangeBreadcrumbLevel = (index: number): any => {
    if (index > 0) {
      let newUrl = "/" + state.filters.Area?.toLowerCase() + "?level=" + index;
      console.log(index);

      if (state.filters.Area !== null)
        newUrl += "&area=" + encodeURIComponent(state.filters.Area);
      if (index > 1 && state.filters.RotorSize_MWDisplay !== null)
        newUrl +=
          "&rotorSize=" + encodeURIComponent(state.filters.RotorSize_MWDisplay);
      if (index > 2 && state.filters.MkMajor_MkMinor !== null)
        newUrl +=
          "&mkMajorMkMinor=" +
          encodeURIComponent(state.filters.MkMajor_MkMinor);
      if (index > 3 && state.filters.Freq !== null)
        newUrl += "&freq=" + encodeURIComponent(state.filters.Freq);
      if (index > 4 && state.filters.CategoryName !== null)
        newUrl += "&category=" + encodeURIComponent(state.filters.CategoryName);
      if (index > 5 && state.filters.SubCategoryName !== null)
        newUrl +=
          "&subCategory=" + encodeURIComponent(state.filters.SubCategoryName);
      if (index > 6 && state.filters.ItemName !== null)
        newUrl += "&itemName=" + encodeURIComponent(state.filters.ItemName);

      navigate(newUrl);
      window.location.reload();
    }
  };

  const SetNextState = () => {
    state.url = "/" + state.filters.Area?.toLowerCase() + "?";
    if (state.filters.Level != null)
      state.filters.Level = state.filters.Level + 1;
    state.url = state.url + "level=" + state.filters.Level;
    if (state.filters.Area != null)
      state.url = state.url + "&area=" + encodeURIComponent(state.filters.Area);
    if (state.filters.RotorSize_MWDisplay != null)
      state.url =
        state.url +
        "&rotorSize=" +
        encodeURIComponent(state.filters.RotorSize_MWDisplay);
    if (state.filters.MkMajor_MkMinor != null)
      state.url =
        state.url +
        "&mkMajorMkMinor=" +
        encodeURIComponent(state.filters.MkMajor_MkMinor);
    if (state.filters.Freq != null)
      state.url = state.url + "&freq=" + encodeURIComponent(state.filters.Freq);
    if (state.filters.CategoryName != null)
      state.url =
        state.url +
        "&category=" +
        encodeURIComponent(state.filters.CategoryName);
    if (state.filters.SubCategoryName != null)
      state.url =
        state.url +
        "&subCategory=" +
        encodeURIComponent(state.filters.SubCategoryName);
    if (state.filters.ItemName != null)
      state.url =
        state.url + "&itemName=" + encodeURIComponent(state.filters.ItemName);
    if (state.filters.Language != null)
      state.url = state.url + "&languages=" + state.filters.Language;

    setState(state);
  };

  useEffect(() => {
    console.log("back");
    const level = searchParams.get("level");
    const language = searchParams.get("languages");

    state.filters.Level = level ? parseInt(level) : 0;
    state.filters.Area = searchParams.get("area");
    state.filters.RotorSize_MWDisplay = searchParams.get("rotorSize");
    state.filters.MkMajor_MkMinor = searchParams.get("mkMajorMkMinor");
    state.filters.Freq = searchParams.get("freq");
    state.filters.CategoryName = searchParams.get("category");
    state.filters.SubCategoryName = searchParams.get("subCategory");
    state.filters.ItemName = searchParams.get("itemName");
    state.filters.Language = language ? JSON.parse(language) : null;
    setState(state);
    setDoRefresh(!doRefresh);
  }, [searchParams]);

  useEffect(() => {
    console.log("getting items");
    if (state.filters.Level < 7) {
      setItems({ data: data ?? [] });
    } else if (state.filters.Level === 7 && data) {
      setDocuments(data);
    }
  }, [data, state.filters, doRefresh]);

  useEffect(() => {
    if (b2cUserViewAccessStatus === "success" && !isVestasMail) {
      const commonError = "User does not have access to: ";
      if (
        (area === "Construction" &&
          !b2cUserViewAccessData.canViewConstruction) ||
        (area === "Service" && !b2cUserViewAccessData.canViewService) ||
        (area === "Transport" && !b2cUserViewAccessData.canViewTransport)
      ) {
        AddError(commonError + area);
        navigate("/");
      }
    }
  }, [b2cUserViewAccessData, b2cUserViewAccessStatus]);

  const RenderBreadcrumbs = () => {
    const breadcrumbLevels: string[] = [];

    //All crumbs are -1 in level, so when frequence is not null,
    //then push category, because that is the subject next to chosen
    if (state.filters.Area) {
      breadcrumbLevels.push(state.filters.Area);
      breadcrumbLevels.push("Product");
    }

    if (state.filters.RotorSize_MWDisplay) {
      breadcrumbLevels[1] = String(state.filters.RotorSize_MWDisplay);
      breadcrumbLevels.push("Variant");
    }
    if (state.filters.MkMajor_MkMinor) {
      breadcrumbLevels[2] = String(state.filters.MkMajor_MkMinor);
      breadcrumbLevels.push("Frequency");
    }
    if (state.filters.Freq) {
      breadcrumbLevels[3] = String(state.filters.Freq);
      breadcrumbLevels.push("Category");
    }
    if (state.filters.CategoryName) {
      breadcrumbLevels[4] = String(state.filters.CategoryName);
      breadcrumbLevels.push("SubCategory");
    }
    if (state.filters.SubCategoryName) {
      breadcrumbLevels[5] = String(state.filters.SubCategoryName);
      breadcrumbLevels.push("Document Type");
    }
    if (state.filters.ItemName) {
      breadcrumbLevels[6] = String(state.filters.ItemName);
      breadcrumbLevels.push("Document");
    }

    return (
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbLevels.map(function (level: string, i: number) {
          return (
            <Typography
              color={i === 0 ? "text.secondary" : "text.primary"}
              variant="h6"
              key={i}
              style={{
                textDecoration: i > 0 ? "underline" : "none",
                textDecorationThickness: "7%",
              }}
              onClick={() => {
                ChangeBreadcrumbLevel(i);
              }}
            >
              {level}
            </Typography>
          );
        })}
      </Breadcrumbs>
    );
  };

  const Items = () => {
    if (state.filters.Level < 7) {
      if (items.data.length !== 0) {
        if (state.filters.Level === 1) {
          const turbines = items.data;

          return (
            <ListGroup className="docsList">
              {turbines.map((item: string) => (
                <Row key={item}>
                  <Col></Col>
                  <Col xs={matchesThemeMd ? 10 : 8}>
                    <ListGroup.Item
                      action
                      onClick={() => {
                        GetNextItems(item);
                      }}
                    >
                      {item}
                      <span className="float-right">
                        <img
                          src={caretImg}
                          style={{ verticalAlign: "middle" }}
                        />
                      </span>
                    </ListGroup.Item>
                  </Col>
                  <Col></Col>
                </Row>
              ))}
            </ListGroup>
          );
        }
        return (
          <ListGroup className="docsList">
            {items.data.map((item) => (
              <Row key={item}>
                <Col></Col>
                <Col xs={matchesThemeMd ? 10 : 8}>
                  <ListGroup.Item
                    action
                    onClick={() => {
                      GetNextItems(item);
                    }}
                  >
                    {item}
                    <span className="float-right">
                      <img src={caretImg} style={{ verticalAlign: "middle" }} />
                    </span>
                  </ListGroup.Item>
                </Col>
                <Col></Col>
              </Row>
            ))}
          </ListGroup>
        );
      } else {
        return null;
      }
    } else if (state.filters.Level === 7) {
      if (documents && documents.length !== 0) {
        const filterPreferredLanguage = preferredLanguage?.language ?? "ENG";

        let preferredDocuments = documents.filter(
          (document) => document.language === filterPreferredLanguage
        );

        // Fallback if no documents with preferred language filter is found
        if (
          preferredDocuments === undefined ||
          preferredDocuments.length === 0
        ) {
          preferredDocuments = documents.filter(
            (document) => document.language === "ENG"
          );
        }

        preferredDocuments = preferredDocuments.sort((a, b) =>
          a.dmsNumber.localeCompare(b.dmsNumber)
        );

        if (preferredDocuments.length !== 0) {
          return (
            <ListGroup className="docsList">
              <Row key={"header"}>
                <Col></Col>
                <Col xs={matchesThemeMd ? 10 : 8}>
                  <div className="cols header">
                    <span style={{ width: "40%" }}>
                      {" "}
                      DMS Number - Document Name{" "}
                    </span>
                    <span style={{ width: "20%" }}> Version </span>
                    {!matchesThemeMd && <span style={{ width: "20%" }}> Last Updated </span>}
                    <span style={{ width: "20%" }}> Language </span>
                  </div>
                </Col>
                <Col></Col>
              </Row>
              {preferredDocuments.map((document) => (
                <Row key={document.documentId}>
                  <Col></Col>
                  <Col xs={matchesThemeMd ? 10 : 8}>
                    {isExcelFile(document.documentFormat) ? (
                      <a
                        href={document.documentData}
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DocumentItem document={document} hideLastUpdatedColumn={matchesThemeMd} />
                      </a>
                    ) : (
                      <RouterLink
                        to={
                          isVideoFile(document.documentFormat)
                            ? `/videoPlayerTdoc`
                            : "/pdfviewer"
                        }
                        state={{ documents: [document] }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <DocumentItem document={document} hideLastUpdatedColumn={matchesThemeMd} />
                      </RouterLink>
                    )}
                  </Col>
                  <Col></Col>
                </Row>
              ))}
            </ListGroup>
          );
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <div className="doc-select-container">
      <div className="doc-select-header center" style={{width: matchesThemeMd ? "83%" : "66%"}}>
        <RenderBreadcrumbs />
      </div>
      {isLoading ? <LoadSpinner /> : <Items />}
    </div>
  );
};

type DocumentItemProps = {
  document: Document;
  hideLastUpdatedColumn?: boolean;
}

const DocumentItem = ({ document, hideLastUpdatedColumn }: DocumentItemProps) => {
  return (
    <ListGroup.Item action>
      <div className="cols">
        <span style={{ width: "40%" }}>
          {" "}
          {document.dmsNumber} - {document.documentName}{" "}
        </span>
        <span style={{ width: "20%" }}>
          {" "}
          {document.version}{" "}
        </span>
        {!hideLastUpdatedColumn && (<span style={{ width: "20%" }}>
          {" "}
          {new Date(
            document.lastUpdatedOn
          ).toLocaleDateString()}{" "}
        </span>)}
        <span style={{ width: "20%" }}>
          <div
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              marginRight: "20px",
              float: "left",
            }}
          >
            {document.language}
          </div>
          <div
            style={{
              float: "right",
              display: "inline-block",
              verticalAlign: "middle",
            }}
          >
            <img src={caretImg} />
          </div>
        </span>
      </div>
    </ListGroup.Item>
  )
}