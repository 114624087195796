import { useState, useMemo, useEffect } from "react";
import { LoadSpinner } from "./LoadSpinner";
import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "../styles/Tools.scss";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";
import { useNavigate } from "react-router";
import { useSearch } from "../apis/tools";

interface StateError {
  mode: string;
  message?: string;
}

export const Tools = () => {
  const [loading] = useState(false);

  const [materialNo, setMaterialNo] = useState("");
  const [serialNo, setSerialNo] = useState("");

  const [searchMaterialNo, setSearchMaterialNo] = useState("");
  const [searchSerialNo, setSearchSerialNo] = useState("");

  const { data, isLoading, status } = useSearch(
    searchMaterialNo,
    searchSerialNo
  );

  const navigate = useNavigate();

  const GoToViewer = useMemo(() => {
    if (data != null && data.length === 1) {
      navigate("/toolcertificateviewer", { state: { url: data[0].dmsurl } });
    } else if (data != null && data.length >= 1) {
      navigate("/toolsresult", { state: { certificates: data } });
    }
  }, [data, navigate, searchMaterialNo, searchSerialNo, isLoading]);

  return (
    <div className="lookup-container center">
      <Form className="form-container">
        <Typography variant="h5" align="center" style={{ marginTop: "30px" }}>
          Tools
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          style={{ marginTop: "5px", marginBottom: "70px", color: "#555" }}
        >
          Write tool numbers
        </Typography>
        <Row>
          <Col md={{ span: 6, offset: 3 }}></Col>
        </Row>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Box
              component="form"
              noValidate
              sx={{ marginTop: "20px", maxWidth: "80%", margin: "20px auto" }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                style={{ marginBottom: "5px", color: "#555" }}
              >
                1. Add material number
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                type="searchQuery"
                label="Material Number"
                placeholder="e.g. 123456"
                sx={{ marginBottom: "50px" }}
                value={materialNo}
                onChange={(e) => setMaterialNo(e.target.value)}
              />
              <Typography
                variant="subtitle1"
                align="left"
                style={{ marginBottom: "5px", color: "#555" }}
              >
                2. Add serial number
              </Typography>
              <TextField
                variant="outlined"
                type="searchQuery"
                fullWidth
                label="Serial Number"
                placeholder="e.g. AB-1234"
                sx={{ marginBottom: "50px" }}
                value={serialNo}
                onChange={(e) => setSerialNo(e.target.value)}
              />
            </Box>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 6, offset: 3 }}></Col>
        </Row>
        <Row className="button">
          <Col md={{ span: 6, offset: 3 }}>
            <div className="SearchButton">
              <Button
                className="button"
                variant="contained"
                sx={{
                  backgroundColor: "#00B5E4",
                  borderColor: "#00B5E4",
                  borderRadius: "4px",
                }}
                onClick={() => {
                  setSearchMaterialNo(materialNo);
                  setSearchSerialNo(serialNo);
                }}
              >
                Retrieve certificate
              </Button>
              {GoToViewer}
            </div>
          </Col>
        </Row>
      </Form>
      {isLoading && <LoadSpinner />}
    </div>
  );
};
