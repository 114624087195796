import { useLocation, useParams } from "react-router-dom";
import { useVideo } from "../apis/useVideo";
import { Video } from "../models/video";
import { LoadSpinner } from "./LoadSpinner";

export const VideoPlayer = () => {
  const { id } = useParams();

  const location = useLocation();
  const stateData = (location.state as any)?.data as Video;

  const { data, isLoading, isSuccess, isError } = useVideo(id ?? "", {
    enabled: id !== undefined,
    initialData: stateData,
    refetchOnWindowFocus: false,
  });

  if (isError && stateData === undefined) {
    return <h2>Video does not exist</h2>;
  }

  return (
    <>
      {isLoading && <LoadSpinner />}
      {(isSuccess || stateData !== undefined) && (
        <>
          <h2>{data?.friendlyName ?? ""}</h2>
          <video width="640" controls>
            <source src={data?.urlString} />
          </video>
        </>
      )}
    </>
  );
};
