import "../styles/App.scss";
import lookupImg from "../images/lookup.svg";
import toolsImg from "../images/tools.svg";
import favoriteImg from "../images/favorite.svg";
import videoImg from "../images/video.svg";
import safetyImg from "../images/safety.svg";
import historyImg from "../images/history.svg";
import constructionImg from "../images/construction.svg";
import transportationImg from "../images/transport.svg";
import serviceImg from "../images/service.svg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import {
  useGetPreferredLanguage,
  useGetSupportedLanguages,
  useSetPreferredLanguage,
} from "../apis/preferredLanguage";
import { LoadSpinner } from "./LoadSpinner";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { EmptyMainMenuButton, MainMenuButton } from "./MainMenuButton";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router";
import { useGetIfVestasMail, useIsTestUser } from "../apis/authConfig";
import {
  useAcceptB2CTermsOfUse,
  useB2CUserHasGrantedPermission,
  useB2CUserViewAccess,
} from "../apis/userManagement";
import { ScheduledMessage } from "./ScheduledMessage";

export const Home = () => {
  let reloadTimeout: any = null;
  const navigate = useNavigate();
  const { accounts } = useMsal();
  const isTestUser = useIsTestUser();
  const isVestasMail = useGetIfVestasMail();
  const [validUser, setValidUser] = useState<boolean>(true);
  const [fetchViewAcces, setFetchViewAccess] = useState<boolean>(false);
  const [doPermissionGrant, setDoPermissionGrant] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(
    "ENG"
  );
  const { mutate: setPreferredLanguage, isLoading: permissionLoading } =
    useSetPreferredLanguage();

  const {
    data: supportedLanguages,
    isLoading: isSupportedLanguagesLoading,
    status: isSupportedLanguagesStatus,
  } = useGetSupportedLanguages();

  const {
    data: preferredLanguage,
    isLoading: isPreferredLanguageLoading,
    isFetched: isPreferredLanguageFetched,
  } = useGetPreferredLanguage();

  const {
    data: b2cUserHasGrantedPermissionData,
    status: b2cUserHasGrantedPermissionStatus,
    isLoading: isb2cUserHasGrantedPermissionLoading,
  } = useB2CUserHasGrantedPermission();

  const {
    data: b2cUserViewAccessData,
    status: b2cUserViewAccessStatus,
    isLoading: isb2cUserViewAccessLoading,
  } = useB2CUserViewAccess(fetchViewAcces);

  const { mutate: acceptB2CTermsOfUse } = useAcceptB2CTermsOfUse();

  const isLoading =
    isSupportedLanguagesLoading ||
    isPreferredLanguageLoading ||
    isb2cUserHasGrantedPermissionLoading ||
    isb2cUserViewAccessLoading;

  useEffect(() => {
    setValidUser(true);
    if (!isVestasMail) {
      if (
        b2cUserHasGrantedPermissionData !== undefined &&
        b2cUserHasGrantedPermissionData.validUser
      ) {
        //If user has grant permitted, then fetch acces level
        if (b2cUserHasGrantedPermissionData.permissionGranted) {
          setFetchViewAccess(true);
        }
        //Else set permit grant action
        else {
          setDoPermissionGrant(true);
        }
      }
      if (
        !isVestasMail &&
        (b2cUserHasGrantedPermissionStatus === "error" ||
          (b2cUserHasGrantedPermissionData !== undefined &&
            !b2cUserHasGrantedPermissionData.validUser))
      ) {
        //User is not valid
        setValidUser(false);
      }
    }
    return () => {
      if (reloadTimeout) {
        clearTimeout(reloadTimeout);
      }
    };
  }, [b2cUserHasGrantedPermissionData, b2cUserHasGrantedPermissionStatus]);

  function handleGrantPermission() {
    const result = acceptB2CTermsOfUse();
    console.log(result);
    setDoPermissionGrant(false);
    reloadTimeout = setTimeout(() => window.location.reload(), 1000);
  }

  function initLanguage() {
    if (selectedLanguage === null) {
      return "ENG";
    } else {
      return selectedLanguage;
    }
  }

  function showNBText() {
    if (selectedLanguage !== "ENG") {
      return (
        <b>
          NB! Some documents are not available in this language but will be
          shown in English.
        </b>
      );
    }
  }

  const PermissionGrantDialog = () => {
    return (
      <Dialog
        open={doPermissionGrant}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Grant permission to TDoc"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You need to grant permission for TDoc to use your data before using
            the system
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleGrantPermission}
            sx={{ color: "#00B5E4" }}
            autoFocus
          >
            Grant
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const Onboarding = () => {
    return (
      <Modal
        open={
          preferredLanguage?.language == null &&
          isPreferredLanguageFetched &&
          isSupportedLanguagesStatus === "success"
        }
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Box
          sx={{
            position: "absolute",
            textAlign: "center",
            backgroundColor: "rgba(255,255,255,1)",
            borderRadius: "14px",
            padding: "10px",
            width: "26vw",
            top: "15%",
            left: "37vw",
          }}
        >
          <h3>
            <b>Preferred document language</b>
          </h3>
          <br />
          <br />
          <div style={{ margin: "auto", width: "80%" }}>
            <div
              style={{
                textAlign: "left",
                marginBottom: "15px",
                marginLeft: "28px",
                width: "250px",
              }}
            >
              Select the language you want to see documents in:
            </div>
            <div>
              <FormControl style={{ width: "250px" }}>
                <Select
                  value={initLanguage()}
                  onChange={(e) => setSelectedLanguage(e.target.value || "")}
                >
                  {supportedLanguages?.map((language: any) => (
                    <MenuItem key={language.name} value={language.name.trim()}>
                      {language.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                textAlign: "left",
                fontSize: "12px",
                marginTop: "5px",
                marginLeft: "28px",
                width: "250px",
              }}
            >
              {showNBText()}
            </div>
            <br />
            <br />
            <br />
            <br />
            <hr />
            <div
              style={{
                textAlign: "left",
                fontSize: "11px",
                marginLeft: "28px",
                width: "250px",
              }}
            >
              You can change it again under “profile” or set another language
              directly under the document.
            </div>
          </div>
          <br />
          <br />
          <Button
            style={{ width: "100px" }}
            variant="contained"
            onClick={() => setPreferredLanguage(selectedLanguage)}
          >
            Save
          </Button>
        </Box>
      </Modal>
    );
  };

  const NavMenu = () => {
    const theme = useTheme();
    const matchesThemeXs = useMediaQuery(theme.breakpoints.only("xs"));

    const canViewConstruction =
      !isTestUser &&
      (isVestasMail || b2cUserViewAccessData?.canViewConstruction);
    const canViewTransport =
      !isTestUser && (isVestasMail || b2cUserViewAccessData?.canViewTransport);
    const canViewService =
      !isTestUser && (isVestasMail || b2cUserViewAccessData?.canViewService);

    return (
      <Stack justifyContent={"center"} alignItems={"center"}>
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-around"}
          gap={2}
          maxWidth={500}
        >
          {canViewConstruction && (
            <MainMenuButton
              href="/construction?level=1&area=Construction"
              iconSrc={constructionImg}
              title="Construction"
            />
          )}
          {canViewTransport && (
            <MainMenuButton
              href="/transport?level=1&area=Transport"
              iconSrc={transportationImg}
              title="Transport"
            />
          )}
          {canViewService && (
            <MainMenuButton
              href="/service?level=1&area=Service"
              iconSrc={serviceImg}
              title="Service"
            />
          )}
          <MainMenuButton href="/lookup" iconSrc={lookupImg} title="Lookup" />
          {!isTestUser && (
            <MainMenuButton href="/tools" iconSrc={toolsImg} title="Tools" />
          )}
          {!isTestUser && (
            <MainMenuButton href="/videos" iconSrc={videoImg} title="Videos" />
          )}
          {!isTestUser && (
            <MainMenuButton href="/safety" iconSrc={safetyImg} title="Safety" />
          )}
          <MainMenuButton
            href="/history"
            iconSrc={historyImg}
            title="History"
          />
          <MainMenuButton
            href="/favorite"
            iconSrc={favoriteImg}
            title="Favorite"
          />
          {/* Add empty divs to preserve 3x3 or 2x5 grid - essentially makes the bottom row left aligned */}
          {matchesThemeXs && <EmptyMainMenuButton />}
          {!canViewConstruction && <EmptyMainMenuButton />}
          {!canViewTransport && <EmptyMainMenuButton />}
          {!canViewService && <EmptyMainMenuButton />}
        </Stack>
      </Stack>
    );
  };

  const NotValidUserFlow = () => {
    return (
      <div>
        <Typography variant="h5" align="center" style={{ marginTop: "30px" }}>
          Wait a minute!
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          style={{ marginTop: "5px", marginBottom: "50px", color: "#555" }}
        >
          It seems like you are not assigned correctly to the Moble
          Documentation platform.
          <br />
          Click below to create a support ticket.
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/support?topic=GetAccess")}
        >
          Send support ticket
        </Button>
      </div>
    );
  };

  return (
    <Box bgcolor="#E5E5E5" height="100%">
      <Stack padding={12} sx={{ p: { xs: 4, md: 12 } }}>
        <ScheduledMessage uiLocation="main" />
        <Box>{validUser ? <NavMenu /> : <NotValidUserFlow />}</Box>
        {isLoading || permissionLoading ? <LoadSpinner /> : <Onboarding />}
        <PermissionGrantDialog />
      </Stack>
    </Box>
  );
};
