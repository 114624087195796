import { useState, useRef, useEffect } from "react";
import "../styles/pdfviewer.scss";
import WebViewer from "@pdftron/pdfjs-express-viewer";
import { useSearchParams } from "react-router-dom";

export const PDFViewerMobile = () => {
  const [doc, setDoc] = useState<any | null>("");
  const [PdfInstance, setPdfInstance] = useState<any | null>(null);
  const [searchParams] = useSearchParams();
  const viewer = useRef<HTMLDivElement | null>(null);
  const libPath = window.origin + "/webviewer/lib";
  const disclaimerDocument = [require("../files/Disclaimer.pdf")];

  useEffect(() => {
    WebViewer(
      {
        licenseKey: "jIldL8xw61vnoudF2ZvO",
        path: libPath,
        disabledElements: ["filePickerButton", "downloadButton", "printButton"],
        extension: "pdf",
      },
      viewer.current!!
    )
      .then((instance: any) => {
        setPdfInstance(instance);
      })
      .catch((e: any) => console.log(e));
  }, [searchParams]);

  const initPDFViewer = () => {
    const docType = encodeURIComponent(searchParams.get("docType") ?? "");

    if (PdfInstance !== null) {
      if (docType === "diclaimer" && disclaimerDocument.length !== 0) {
        PdfInstance.UI.loadDocument(disclaimerDocument[0]);
      } else {
        const fileName = encodeURIComponent(searchParams.get("fileName") ?? "");
        const type = encodeURIComponent(searchParams.get("type") ?? "");
        const dms = encodeURIComponent(searchParams.get("dms") ?? "");
        const version = encodeURIComponent(searchParams.get("version") ?? "");
        const email = encodeURIComponent(searchParams.get("email") ?? "");
        const sas = encodeURIComponent(searchParams.get("sas") ?? "");
        const platform = encodeURIComponent(searchParams.get("platform") ?? "");

        if (PdfInstance != null) {
          const url = `${process.env.REACT_APP_API_B2C_BASE_URL}/file/v1/document/download?fileName=${fileName}&type=${type}&dms=${dms}&version=${version}&email=${email}&sas=${sas}&platform=${platform}`;
          console.log(url);
          PdfInstance.UI.loadDocument(url);
        }
      }
    }
  };

  return (
    <>
      {initPDFViewer()}

      <div
        className="mobile-viewer"
        ref={viewer}
        style={{ height: "100vh", width: "100vw", marginTop: "25px" }}
      />
    </>
  );
};
