import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { DropdownMenu } from "./DropdownMenu";
import { AppBar, Link, Stack, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import { VideoLink } from "./VideoLink";

interface Props {
  children: React.ReactNode;
}

export const PageLayout = ({ children }: Props) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{ background: "#6E757C" }}>
          <Toolbar
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Link
              href="/"
              variant="h6"
              sx={{
                textDecoration: "none",
                color: "white",
                "&:hover": {
                  color: "white",
                },
                textDecorationColor: "white",
                width: "16vw",
              }}
              color="#FFF"
            >
              Vestas Documentation
            </Link>
            <Box sx={{ flexGrow: 2 }} />
            {isAuthenticated && (
              <Stack gap={3} direction={"row"} alignItems={"center"}>
                <VideoLink />
                <DropdownMenu />
              </Stack>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      {children}
    </>
  );
};
