import { useMutation } from "react-query";
import { useGetToken, useGetApi, useGetUsername } from "./authConfig";

interface Props {
  topic: string;
  message: string;
}

export const useSendSupportTicket = () => {
  const tokenPromise = useGetToken();
  const api = useGetApi();
  const username = useGetUsername();

  return useMutation(async ({ topic, message }: Props) => {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    const token = await tokenPromise;

    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("access-control-allow-origin", "*");
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        Topic: topic,
        Message: message + " kind regards " + username,
      }),
    };

    return fetch(api + "/support/v1/SendSupportTicket", options)
      .then((res) => res.json())
      .catch((error) => console.log(error));
  });
};
