import { LinearProgress, Stack, Typography } from "@mui/material";

export const AccessCheckingPage = () => {
  return (
    <Stack
      direction={"column"}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={3}
    >
      <Typography variant="h4">Checking your access</Typography>
      <LinearProgress sx={{ width: 500 }} />
      <Typography>
        Try refreshing the page if it takes more than 30 seconds
      </Typography>
    </Stack>
  );
};
