import { useQuery, UseQueryOptions } from "react-query";
import { Video } from "../models/video";
import { useGetApi, useGetToken } from "./authConfig";

export const useVideo = (
  filename: string,
  options?: UseQueryOptions<Video, unknown, Video>
) => {
  const tokenPromise = useGetToken();
  const api = useGetApi();

  return useQuery<Video, unknown, Video>(
    ["video", filename],
    async () => {
      const token = await tokenPromise;

      const headers = new Headers();
      headers.append("Authorization", token);
      headers.append("access-control-allow-origin", "*");

      const options = {
        method: "GET",
        headers: headers,
      };

      const encodedFilename = encodeURIComponent(filename);

      const res = await fetch(
        `${api}/documents/v1/GetVideo/${encodedFilename}`,
        options
      );
      if (!res.ok) {
        throw new Error("Video not found");
      }

      return res.json();
    },
    options
  );
};
