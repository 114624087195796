import { Link, Stack, Typography } from "@mui/material";

const email = "docmanagement@vestas.com";
const emailMailTo = `mailto:${email}`;

export const DeleteUser = () => {
  return (
    <Stack
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography fontSize={18}>
        To delete a user please send an email to{" "}
        <Link href={emailMailTo} target="_top">
          {email}
        </Link>
        {" "}from the email of the user to be deleted
      </Typography>
    </Stack>
  );
};
