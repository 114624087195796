export const StoreItem = (key: string, item: any) => {
  sessionStorage.setItem(
    key,
    JSON.stringify({ item: item, timestamp: Date.now() })
  );
};

export const DeleteItem = (key: string) => {
  sessionStorage.removeItem(key);
};

export const GetItem = (
  key: string,
  options: { StaleTime: number } | null = null
) => {
  const rawItem = sessionStorage.getItem(key);
  if (rawItem == null) return null;

  const item = JSON.parse(rawItem);
  if (item == null) return null;

  if (options == null) return item.item;

  if (Date.now() - item.timestamp > options.StaleTime * 1000) return null;

  return item.item;
};
