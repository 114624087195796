import React from "react";
import Form from "react-bootstrap/Form";
import { Alert, Snackbar, Typography } from "@mui/material";
import "../styles/Support.css";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Modal from "react-bootstrap/esm/Modal";
import "../styles/Support.css";
import { useEffect, useState } from "react";
import { LoadSpinner } from "./LoadSpinner";
import { Button } from "@mui/material";
import { useSendNewUserTicket } from "../apis/newUser";

export const NewUser = () => {
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [address, setAddress] = useState("");
  const { mutate: sendTicket, isLoading, isSuccess } = useSendNewUserTicket();

  const handleSnackBarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setOpenSnackBar(false);
  };

  const SendTicket = () => {
    const topic = "New User Access to Vestas Mobile Documenation";
    const message = `User with name: ${name} and email: ${email} from company: ${company} with address: ${address} wants to join Vestas Mobile Documenation`;

    if (name.length !== 0 && email.length !== 0 && company.length !== 0) {
      sendTicket({ topic, message });
      setOpenSnackBar(true);
      setName("");
      setEmail("");
      setCompany("");
      setAddress("");
    }
  };

  useEffect(() => {
    console.log("confirmed");
  }, [openSnackBar]);

  return (
    <>
      <br />
      <Form>
        <Typography variant="h5" align="center" style={{ marginTop: "30px" }}>
          Welcome to Vestas Doc App
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          style={{ marginTop: "5px", marginBottom: "50px", color: "#555" }}
        >
          Fill in your information and we will invite you to the application
        </Typography>
        <Row>
          <Col></Col>
          <Col xs={5}>
            <Form.Group className="Form-label-align">
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder=""
                maxLength={150}
                value={name}
                onChange={(event) => setName(event.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col xs={5}>
            <Form.Group className="Form-label-align">
              <Form.Label>Email</Form.Label>
              <Form.Control
                placeholder=""
                maxLength={150}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                type="email"
                required
              />
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col xs={5}>
            <Form.Group className="Form-label-align">
              <Form.Label>Company</Form.Label>
              <Form.Control
                placeholder=""
                maxLength={150}
                value={company}
                onChange={(event) => setCompany(event.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col xs={5}>
            <Form.Group className="Form-label-align">
              <Form.Label>Company Address</Form.Label>
              <Form.Control
                placeholder=""
                maxLength={150}
                value={address}
                onChange={(event) => setAddress(event.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>
        <Button
          variant="contained"
          onClick={() => SendTicket()}
          disabled={
            company.length === 0 ||
            email.length === 0 ||
            name.length === 0 ||
            address.length === 0
          }
          sx={{ mt: 2 }}
        >
          Send
        </Button>
        <Modal
          show={isSuccess}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body
            style={{
              textAlign: "center",
              backgroundColor: "rgba(255,255,255,1)",
              borderRadius: "0.3rem",
            }}
          >
            <h3>Message is sent</h3>
            <div style={{ width: "20vw", display: "inline-block" }}>
              You will hear from us on your email {email} as soon as possible.
            </div>
            <br />
            <br />
            <Button href="/">Close</Button>
          </Modal.Body>
        </Modal>
      </Form>
      {isLoading && <LoadSpinner />}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={4000}
        open={openSnackBar}
        onClose={handleSnackBarClose}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Successfully added ticket
        </Alert>
      </Snackbar>
    </>
  );
};
