import { useGetToken, useGetApi, useGetUsername } from "./authConfig";
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { GetDocumentsResponse } from "./lookup";

export const useIsFavorite = (dmsNumber: string) => {
  const username = useGetUsername();
  const tokenPromise = useGetToken();
  const api = useGetApi();

  return useQuery(["isFavorite", dmsNumber], async () => {
    const token = await tokenPromise;

    const headers = new Headers();
    headers.append("Authorization", token);

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(
      `${api}/user/v1/IsDocumentInFavorites?username=${username}&dmsNumber=${dmsNumber}`,
      options
    ).then((res) => res.json());
  });
};

export const useMarkDocumentAsFavorite = (dmsNumber: string) => {
  const queryClient = useQueryClient();
  const username = useGetUsername();
  const tokenPromise = useGetToken();
  const api = useGetApi();

  return useMutation(
    async () => {
      const token = await tokenPromise;

      const headers = new Headers();
      headers.append("Authorization", token);

      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          username: username,
          dmsNumber: dmsNumber,
        }),
      };

      return fetch(`${api}/user/v1/MarkDocumentAsFavorite`, options);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["isFavorite", dmsNumber]);
        queryClient.invalidateQueries("favorites");
      },
    }
  );
};

export const useUnmarkDocumentAsFavorite = (dmsNumber: string) => {
  const queryClient = useQueryClient();
  const username = useGetUsername();
  const tokenPromise = useGetToken();
  const api = useGetApi();

  return useMutation(
    async () => {
      const token = await tokenPromise;

      const headers = new Headers();
      headers.append("Authorization", token);

      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          username: username,
          dmsNumber: dmsNumber,
        }),
      };

      return fetch(`${api}/user/v1/UnmarkDocumentAsFavorite`, options);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["isFavorite", dmsNumber]);
        queryClient.invalidateQueries("favorites");
      },
    }
  );
};

export const useFavoriteDocuments =
  (): UseQueryResult<GetDocumentsResponse> => {
    const username = useGetUsername();
    const tokenPromise = useGetToken();
    const api = useGetApi();
    return useQuery("favorites", async () => {
      const token = await tokenPromise;

      const headers = new Headers();
      headers.append("Authorization", token);

      const options = {
        method: "GET",
        headers: headers,
      };

      return fetch(
        `${api}/user/v1/GetFavoriteDocuments?username=${username}&metadata=false`,
        options
      ).then((res) => res.json());
    });
  };
