import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

interface Props {
  iconSrc: string;
  title: string;
  href: string;
}

export const MainMenuButton = ({ iconSrc, title, href }: Props) => {
  return (
    <Button
      variant="text"
      href={href}
      sx={{
        borderRadius: "10px",
        backgroundColor: "white",
        height: "140px",
        width: "140px",
        textTransform: "none",
      }}
    >
      <Stack spacing={2}>
        <Box>
          <img height={48} width={48} src={iconSrc} alt={title} />
        </Box>
        <Typography
          variant="body1"
          color="black"
          fontWeight="bold"
          fontSize="18px"
        >
          {title}
        </Typography>
      </Stack>
    </Button>
  );
};

export const EmptyMainMenuButton = () => {
  return (
    <div style={{
      height: "140px",
      width: "140px",
    }}>
    </div>
  )
}
