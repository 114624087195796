import { useGetToken, useGetApi, useGetUsername } from "./authConfig";
import { useQuery } from "react-query";

export const useSafetyDocuments = () => {
  const tokenPromise = useGetToken();
  const api = useGetApi();

  return useQuery("videos", async () => {
    const token = await tokenPromise;

    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("access-control-allow-origin", "*");

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(`${api}/documents/v1/GetSafetyDocuments`, options).then(
      (res) => res.json()
    );
  });
};
