import { useState, useRef, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";
import WebViewer from "@pdftron/pdfjs-express-viewer";
import { GetToken } from "../apis/authConfig";
import { GetCertificate } from "../apis/tools";
import { ExportBtn } from "./ExportBtn";
export const ToolCertificateViewer = () => {
  const { instance, accounts } = useMsal();
  const location = useLocation();
  const locationState: any = location.state;
  const url = locationState.url;
  const [PdfInstance, setPdfInstance] = useState<any | null>(null);

  const { data } = GetCertificate(url);

  const viewer = useRef<HTMLDivElement | null>(null);
  const libPath = window.origin + "/webviewer/lib";

  useEffect(() => {
    WebViewer(
      {
        licenseKey: process.env.REACT_APP_PDF_LICENSE,
        path: libPath,
        disabledElements: ["filePickerButton", "downloadButton", "printButton"],
        extension: "pdf",
      },
      viewer.current!!
    )
      .then((instance: any) => {
        const { documentViewer } = instance.Core;
        setPdfInstance(instance);
      })
      .catch((e: any) => console.log(e));
  }, [libPath, url]);

  const initPDFViewer = () => {
    if (PdfInstance != null) {
      const token = GetToken();
      token.then((res) => {
        const options = {
          customHeaders: {
            "access-control-allow-origin": "*",
          },
        };

        PdfInstance.UI.loadDocument(data, options);
      });
    }
  };

  return (
    <>
      {initPDFViewer()}

      <div style={{ textAlign: "right" }}>
        <ExportBtn pdfInstance={PdfInstance} />
      </div>

      <div
        className="webviewer"
        ref={viewer}
        style={{ height: "90vh", marginTop: "25px" }}
      />
    </>
  );
};
