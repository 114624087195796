import { useMsal } from "@azure/msal-react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, ListItemIcon, Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import disclaimerImg from "../images/disclaimer.svg";
import languageIconImg from "../images/language.svg";
import logoutIconImg from "../images/logout.svg";
import profileIconImg from "../images/profileIcon.svg";
import supportIconImg from "../images/support.svg";

export const DropdownMenu = () => {
  const { instance, accounts } = useMsal();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const disclaimerDocument = [require("../files/Disclaimer.pdf")];

  const theme = useTheme();
  const matchesThemeXs = useMediaQuery(theme.breakpoints.only("xs"));

  const Logout = () => {
    queryClient.clear();
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  const ShowPreferredLanguage = () => {
    navigate("/preferredLanguage");
  };

  const ShowSupport = () => {
    navigate("/support");
  };

  const ShowDisclaimerDocument = () => {
    if (disclaimerDocument.length !== 0) {
      console.log(disclaimerDocument);
      navigate("/pdfviewer", {
        state: { documents: disclaimerDocument, mode: "Special" },
      });
    }
  };

  const size = { width: "20px", height: "20px", display: "inline-block" };

  const itemIcons = {
    width: "17px",
    height: "17px",
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: "10px",
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (accounts.length === 0) {
    return <></>;
  }

  return (
    <div className="ml-auto">
      <Button
        variant="text"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          textDecoration: "none",
          color: "white",
          "&:hover": "white",
          textDecorationColor: "white",
        }}
      >
        <img src={profileIconImg} alt="profile" style={size} />
        &nbsp;&nbsp;
        {!matchesThemeXs && accounts[0].name}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        className="ml-auto"
        style={{ display: "inline-block" }}
        title={accounts[0].name}
      >
        <MenuItem
          onClick={() => {
            ShowPreferredLanguage();
            handleClose();
          }}
        >
          <ListItemIcon>
            <img
              src={languageIconImg}
              alt="Document language"
              style={itemIcons}
            />
          </ListItemIcon>
          Document language
        </MenuItem>
        <MenuItem
          onClick={() => {
            ShowSupport();
            handleClose();
          }}
        >
          <ListItemIcon>
            <img src={supportIconImg} alt="Support" style={itemIcons} />
          </ListItemIcon>
          Support
        </MenuItem>
        <MenuItem
          onClick={() => {
            ShowDisclaimerDocument();
            handleClose();
          }}
        >
          <ListItemIcon>
            <img src={disclaimerImg} alt="Disclaimer" style={itemIcons} />
          </ListItemIcon>
          Disclaimer
        </MenuItem>
        <MenuItem onClick={Logout}>
          <ListItemIcon>
            <img src={logoutIconImg} alt="Sign out" style={itemIcons} />
          </ListItemIcon>
          Sign out
        </MenuItem>
      </Menu>
    </div>
  );
};
