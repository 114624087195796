import { Link } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useGetPreferredLanguage } from "../apis/preferredLanguage";
import { useClearHistory, useHistory } from "../apis/useHistory";
import caretImg from "../images/caret-right.svg";
import { LoadSpinner } from "./LoadSpinner";
import "../styles/History.css";
import "../styles/docList.scss";
import { Button, Typography } from "@mui/material";
import { convertToObject } from "typescript";
import { HiOutlineTrash } from "react-icons/hi";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { isSinglePdfFile } from "../helpers/utility";

export const History = () => {
  const { data: preferredLanguage, isLoading: isLoadingPreferredLanguage } =
    useGetPreferredLanguage();
  const { data, isLoading: isLoadingHistory } = useHistory();
  const { mutate: clearHistory, isLoading: isClearing } = useClearHistory();

  const isLoading = isLoadingHistory || isLoadingPreferredLanguage;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClearHistory = () => {
    clearHistory();
    setOpen(false);
  };

  const Documents = () => {
    if (data && data.length !== 0) {
      let preferredDocuments: any = [];
      const documents = data.sort(function (a: any, b: any) {
        return (
          new Date(b.Visited as number).getTime() -
          new Date(a.Visited as number).getTime()
        );
      });

      documents.map((document: any) => {
        const filterPreferredLanguage = preferredLanguage?.language ?? "ENG";

        let preferredDocument = document.documents.filter(
          (document: any) => document.language === filterPreferredLanguage
        );

        // Fallback if no documents with preferred language filter is found
        if (preferredDocument.length === 0) {
          preferredDocument = document.documents.filter(
            (document: any) => document.language === "ENG"
          );
        }

        if (preferredDocument.length !== 0) {
          preferredDocuments.push(preferredDocument);
        }
      });

      return (
        <ListGroup className="docsList">
          {preferredDocuments.map((document: any) => (
            <Row>
              <Col></Col>
              <Col xs={8}>
                <Link
                  to={isSinglePdfFile(document) ? "/pdfviewer" : "/result"}
                  state={{
                    documents: document,
                  }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListGroup.Item action>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "20%",
                        }}
                      ></div>
                      <div
                        style={{
                          width: "60%",
                        }}
                      >
                        {document[0].dmsNumber} - {document[0].documentName}
                      </div>
                      <div
                        style={{
                          width: "20%",
                        }}
                      >
                        <span className="float-right">
                          <div
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginRight: "20px",
                              textAlign: "right",
                            }}
                          >
                            {document.length > 1 ? (
                              <span> {document.length} documents </span>
                            ) : (
                              <span> {document[0].language} </span>
                            )}
                          </div>
                          <div
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          >
                            <img src={caretImg} />
                          </div>
                        </span>
                      </div>
                    </div>
                  </ListGroup.Item>
                </Link>
              </Col>
              <Col></Col>
            </Row>
          ))}
        </ListGroup>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="App">
        <br />
        <h3>
          <b>History</b>
        </h3>
        <br />
        <Row>
          <Col></Col>
          <Col xs={8}>
            {data !== undefined && data.length > 0 ? (
              <Button
                sx={{
                  marginTop: 2,
                  backgroundColor: "white",
                  borderColor: "white",
                  color: "black",
                }}
                variant="outlined"
                disabled={isClearing}
                onClick={() => handleClickOpen()}
                className="float-right"
                startIcon={<HiOutlineTrash />}
              >
                CLEAR
              </Button>
            ) : (
              <Typography
                variant="h6"
                align="center"
                style={{ marginTop: "10px", color: "#555" }}
              >
                There are no documents in your history
              </Typography>
            )}
          </Col>
          <Col></Col>
        </Row>
        {isLoading || isClearing ? <LoadSpinner /> : <Documents />}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Clear history"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to clear your history?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} sx={{ color: "#000000" }}>
              Cancel
            </Button>
            <Button
              onClick={handleClearHistory}
              sx={{ color: "#00B5E4" }}
              autoFocus
            >
              Clear
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
