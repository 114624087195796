import { useQuery } from "react-query";
import { useGetApi } from "./authConfig";

interface ScheduledMessage {
  message: string;
  header: string;
  targetText: string;
  targetUrl: string;
  from: Date;
  to: Date;
  showAtLogin: boolean;
  showAtMain: boolean;
}

export const useGetMessage = () => {
  const api = useGetApi();

  return useQuery(["getMessage"], async () => {
    const options = {
      method: "GET",
    };

    return fetch(
      api + "/messages/v1/ScheduledMessage?platform=web",
      options
    ).then((res) => res.json() as Promise<ScheduledMessage>);
  });
};
