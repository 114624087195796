import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider, useQueryClient } from "react-query";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { GetError, RemoveError } from "./apis/error";
import { useExternalUserCheck } from "./apis/useExternalUserCheck";
import { DocSelect } from "./components/DocSelect";
import { Favorite } from "./components/Favorite";
import { History } from "./components/History";
import { Home } from "./components/Home";
import { Lookup } from "./components/Lookup";
import { MutltiResult } from "./components/MutltiResult";
import { NewUser } from "./components/NewUser";
import { PDFViewer } from "./components/PDFViewer";
import { PDFViewerMobile } from "./components/PDFViewerMobile";
import { PageLayout } from "./components/PageLayout";
import { PreferredLanguage } from "./components/PreferredLanguage";
import { SafetyPage } from "./components/SafetyPage";
import { SignIn } from "./components/SignIn";
import { Support } from "./components/Support";
import { ToolCertificateViewer } from "./components/ToolCertificateViewer";
import { Tools } from "./components/Tools";
import { ToolsResult } from "./components/ToolsResult";
import { VideoPlayer } from "./components/VideoPlayer";
import { Videos } from "./components/Videos";
import { AccessCheckingPage } from "./pages/AccessCheckingPage";
import { ExternalUserErrorPage } from "./pages/ExternalUserErrorPage";
import "./styles/App.scss";
import { TDocVideoPlayer } from "./components/TDocVideoPlayer";
import { DeleteUser } from "./components/DeleteUser";

const queryClient = new QueryClient();

const MainContent = () => {
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    setTimeout(function () {
      const errorText = GetError();
      if (errorText) {
        setError(errorText);
        setOpenSnackBar(true);
      }
    }, 2500);
  }, []);

  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const { mutateAsync } = useExternalUserCheck();
  const [isExternalUser, setIsExternalUser] = useState<boolean | undefined>(
    undefined
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    async function runAsyncCode() {
      if (isAuthenticated) {
        const result = await mutateAsync();

        setIsExternalUser(result.isExternal);
        const shouldDenyLogIn = result.isExternal;

        if (shouldDenyLogIn) {
          queryClient.clear();
          instance.logoutRedirect({
            postLogoutRedirectUri: "/external-user",
          });
        }
      }
    }
    runAsyncCode();
  }, [instance, isAuthenticated, mutateAsync, queryClient]);

  const handleSnackBarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setOpenSnackBar(false);
    RemoveError();
  };

  return (
    <div className="App">
      <AuthenticatedTemplate>
        {/* Render loading page while checking if user is internal or external. 
            isExternalUser is undefined while loading. 
            We keep showing AccessCheckingPage even when we know they are external since they will be logged out shortly. */}
        {isExternalUser === undefined || isExternalUser === true ? (
          <AccessCheckingPage />
        ) : (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/lookup" element={<Lookup />} />
            <Route path="/videos" element={<Videos />} />
            <Route path="/videoPlayer/:id" element={<VideoPlayer />}></Route>
            <Route
              path="/videoPlayerTdoc"
              element={<TDocVideoPlayer />}
            ></Route>
            <Route path="/history" element={<History />}></Route>
            <Route path="/safety" element={<SafetyPage />}></Route>
            <Route path="/pdfviewer" element={<PDFViewer />}></Route>
            <Route
              path="/toolcertificateviewer"
              element={<ToolCertificateViewer />}
            ></Route>
            <Route path="/toolsresult" element={<ToolsResult />}></Route>
            <Route
              path="/preferredLanguage"
              element={<PreferredLanguage />}
            ></Route>
            <Route path="/favorite" element={<Favorite />}></Route>
            <Route path="/support" element={<Support />}></Route>
            <Route
              path="/construction"
              element={<DocSelect area="Construction" />}
            ></Route>
            <Route
              path="/transport"
              element={<DocSelect area="Transport" />}
            ></Route>
            <Route
              path="/service"
              element={<DocSelect area="Service" />}
            ></Route>
            <Route path="/tools" element={<Tools />}></Route>
            <Route path="/result" element={<MutltiResult />}></Route>
            <Route path="/delete-user" element={<DeleteUser />}></Route>
          </Routes>
        )}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/" element={<SignIn />}></Route>
          <Route path="/new-user" element={<NewUser />}></Route>
          <Route path="/external-user" element={<ExternalUserErrorPage />} />
          <Route path="*" element={<SignIn />} />
        </Routes>
      </UnauthenticatedTemplate>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        open={openSnackBar}
        onClose={handleSnackBarClose}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        {window.location.pathname.includes("mobile-viewer") ? (
          <PDFViewerMobile />
        ) : (
          <PageLayout>
            <MainContent />
          </PageLayout>
        )}
      </Router>
    </QueryClientProvider>
  );
}
