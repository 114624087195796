import { useState, useEffect } from "react";
import "../styles/ShowPDF.scss";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

interface Props {
  pdfInstance: any;
  language: string;
  data: any;
  mode: any;
}

export const ShowPDF = ({ pdfInstance, language, data, mode }: Props) => {
  const [Language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(language);
  }, []);

  const Languages = () => {
    if (Language !== undefined) {
      return (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Language</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={Language}
            onChange={(event) => {
              setLanguage(event.target.value);
            }}
            sx={{
              height: "38px",
              marginRight: "2px",
              backgroundColor: "#FFFFFF",
            }}
          >
            {data.map((document: any) => (
              <MenuItem
                value={document.language}
                onClick={() => {
                  pdfInstance.UI.loadDocument(document.documentData);
                }}
              >
                {document.language}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return null;
    }
  };

  return mode !== "Special" ? <Languages /> : null;
};
