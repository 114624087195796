import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { useGetToken, useGetApi, useGetUsername } from "./authConfig";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { json } from "stream/consumers";

export const useHistory = () => {
  const username = useGetUsername();
  const tokenPromise = useGetToken();
  const api = useGetApi();

  return useQuery("history", async () => {
    const token = await tokenPromise;

    const headers = new Headers();
    headers.append("Authorization", token);

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(
      `${api}/user/v1/GetDocumentsHistory?username=${username}&metadata=false`,
      options
    ).then((res) => res.json());
  });
};

export const useClearHistory = () => {
  const username = useGetUsername();
  const tokenPromise = useGetToken();
  const api = useGetApi();
  const queryClient = useQueryClient();

  return useMutation(async () => {
    const token = await tokenPromise;

    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(username),
    };

    return fetch(`${api}/user/v1/ClearHistory`, options).finally(() => {
      queryClient.invalidateQueries("history");
    });
  });
};

export const useAddToHistory = (
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
) => {
  const queryClient = useQueryClient();
  const username = useGetUsername();
  const tokenPromise = useGetToken();
  const api = useGetApi();

  return useMutation(async (dmsNumber: string) => {
    const token = await tokenPromise;

    const headers = new Headers();
    headers.append("Authorization", token);

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        username: username,
        dmsNumber: dmsNumber,
      }),
    };

    return fetch(
      `${api}/user/v1/AddDocumentToHistory?username=${username}&dmsNumber=${dmsNumber}`,
      options
    ).finally(() => {
      queryClient.invalidateQueries("history");
    });
  });
};
