import { FilterState } from "../components/DocSelect";
import {
  useGetToken,
  useGetApi,
  useGetUsername,
  useGetIfVestasMail,
} from "./authConfig";
import { useQuery } from "react-query";
import { stringify } from "querystring";

export const useGetItems = (filters: FilterState["filters"]) => {
  const tokenPromise = useGetToken();
  const api = useGetApi();
  const vestasUser = useGetIfVestasMail();

  return useQuery(["filters", filters], async () => {
    const token = await tokenPromise;

    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("access-control-allow-origin", "*");
    headers.append("Content-Type", "application/json");

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(
      api +
        "/documents/v1/GetAllFilters?" +
        CreateUrl(filters, !vestasUser) +
        "&metadata=true",
      options
    ).then((res) => res.json());
  });
};

function CreateUrl(filters: FilterState["filters"], external: boolean) {
  var url = "";

  if (filters.Level != null) {
    url = url + "level=" + encodeURIComponent(filters.Level);
  }

  if (filters.Area != null) {
    url = url + "&" + "area=" + encodeURIComponent(filters.Area);
  }

  if (filters.RotorSize_MWDisplay != null) {
    url =
      url +
      "&" +
      "rotorSize=" +
      encodeURIComponent(filters.RotorSize_MWDisplay);
  }

  if (filters.MkMajor_MkMinor != null) {
    url =
      url +
      "&" +
      "mkMajorMkMinor=" +
      encodeURIComponent(filters.MkMajor_MkMinor);
  }

  if (filters.Freq != null) {
    url = url + "&" + "freq=" + encodeURIComponent(filters.Freq);
  }

  if (filters.CategoryName != null) {
    url = url + "&" + "category=" + encodeURIComponent(filters.CategoryName);
  }

  if (filters.SubCategoryName != null) {
    url =
      url + "&" + "subCategory=" + encodeURIComponent(filters.SubCategoryName);
  }

  if (filters.ItemName != null) {
    url = url + "&" + "item=" + encodeURIComponent(filters.ItemName);
  }

  if (filters.Language != null) {
    url =
      url +
      "&" +
      "languages=" +
      encodeURIComponent(filters.Language.toString());
  }

  url = url + "&" + "external=" + external;

  return url;
}
