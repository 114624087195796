import { Link } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useGetPreferredLanguage } from "../apis/preferredLanguage";
import caretImg from "../images/caret-right.svg";
import "../styles/Favorite.css";
import { LoadSpinner } from "./LoadSpinner";
import { groupBy, isSinglePdfFile } from "../helpers/utility";
import "../styles/docList.scss";
import { useFavoriteDocuments } from "../apis/favorites";
import { Typography } from "@mui/material";
import { useEffect } from "react";

export const Favorite = () => {
  const { data: preferredLanguage } = useGetPreferredLanguage();
  const { data, isLoading } = useFavoriteDocuments();

  const Documents = () => {
    if (!data || data.length === 0) {
      return null;
    }

    const documents = groupBy(data, "dmsNumber");
    let preferredDocuments = [];

    for (const dmsNumber in documents) {
      const filterPreferredLanguage = preferredLanguage?.language ?? "ENG";

      let preferredDocument = documents[dmsNumber].filter(
        (document: any) => document.language === filterPreferredLanguage
      );

      // Fallback if no documents with preferred language filter is found
      if (preferredDocument.length === 0) {
        preferredDocument = documents[dmsNumber].filter(
          (document: any) => document.language === "ENG"
        );
      }

      if (preferredDocument.length !== 0) {
        preferredDocuments.push(preferredDocument);
      }
    }

    return (
      <ListGroup className="docsList">
        {preferredDocuments.map((document) => (
          <Row key={document[0].dmsNumber}>
            <Col></Col>
            <Col xs={8}>
              <Link
                to={isSinglePdfFile(document) ? "/pdfviewer" : "/result"}
                state={{
                  documents: document,
                }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListGroup.Item action>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        width: "20%",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "60%",
                      }}
                    >
                      {document[0].dmsNumber} - {document[0].documentName}
                    </div>
                    <div
                      style={{
                        width: "20%",
                      }}
                    >
                      <span className="float-right">
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            marginRight: "20px",
                            textAlign: "right",
                          }}
                        >
                          {document.length > 1 ? (
                            <span> {document.length} documents </span>
                          ) : (
                            <span> {document[0].language} </span>
                          )}
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                          }}
                        >
                          <img src={caretImg} />
                        </div>
                      </span>
                    </div>
                  </div>
                </ListGroup.Item>
              </Link>
            </Col>
            <Col></Col>
          </Row>
        ))}
      </ListGroup>
    );
  };

  return (
    <>
      <div className="App">
        <Typography
          variant="h5"
          align="center"
          style={{ marginTop: "30px", marginBottom: "40px" }}
        >
          Favorites
        </Typography>
        <Documents />
      </div>
      {isLoading && <LoadSpinner />}
    </>
  );
};
