import { useGetToken, useGetApi } from "./authConfig";
import { UseQueryResult, useQuery } from "react-query";

export enum SearchType {
  DMS,
  Material,
}

interface Translation {
  language: string;
  displayName: string;
}

interface Turbine {
  type: string;
  markVersion: string;
  frequency: string;
  nominelPower: string;
}

export interface GetDocumentsByDMSNumberDocument {
  documentData: string;
  docGUID: string;
  isDeleted: boolean;
  masterDocumentDmsNumber: string | null;
  size: number;
  documentId: number;
  documentName: string;
  docDescription: string;
  dmsNumber: string;
  documentFormat: string;
  language: string;
  version: string;
  subDocumentId: string;
  documentType: string;
  createdOn: string;
  lastUpdatedOn: string;
  lastUpdatedOnFromSAP: string;
  translations: Translation[] | null;
  turbines: Turbine[] | null;
  metaDataCategories: { [key: string]: any } | null;
  metaData: { [key: string]: string } | null;
}

export type GetDocumentsResponse = GetDocumentsByDMSNumberDocument[];

export const useSearch = (
  text: string | null,
  searchType: SearchType
): UseQueryResult<GetDocumentsResponse> => {
  const tokenPromise = useGetToken();
  const api = useGetApi();

  return useQuery(
    [`search${searchType}`, text],
    async () => {
      const token = await tokenPromise;

      const headers = new Headers();
      headers.append("Authorization", token);

      const options = {
        method: "GET",
        headers: headers,
      };

      let endpoint;
      switch (searchType) {
        case SearchType.DMS:
          endpoint = `/documents/v1/GetDocumentsByDMSNumber?dmsNumber=${text}&metadata=false`;
          break;
        case SearchType.Material:
          endpoint = `/documents/v1/GetDocumentsByMaterialNumber?materialNumber=${text}&metadata=false`;
          break;
      }

      return fetch(api + endpoint, options).then((res) => res.json());
    },
    { enabled: text != null && text.length > 0 }
  );
};
