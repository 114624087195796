import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";

export const SignInButton = () => {
  const { instance } = useMsal();

  const loginScopes = {
    scopes: [process.env.REACT_APP_AUTH_SCOPES ?? ""],
  };

  const Login = () => {
    instance.loginRedirect(loginScopes).catch((e) => {
      console.error(e);
    });
  };

  return (
    <Button
      variant="contained"
      onClick={() => Login()}
      sx={{ height: 50, width: 200, borderRadius: "10px" }}
    >
      Sign In
    </Button>
  );
};
