import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useGetToken, useGetApi, useGetUsername } from "./authConfig";
import { StoreItem, GetItem } from "./cache";

interface PreferredLanguage {
  username: string;
  language: string;
  displayName: string;
}

export const useGetPreferredLanguage =
  (): UseQueryResult<PreferredLanguage> => {
    const username = useGetUsername();
    const tokenPromise = useGetToken();
    const api = useGetApi();

    return useQuery("preferredLanguage", async () => {
      const token = await tokenPromise;

      const headers = new Headers();
      headers.append("Authorization", token);

      const options = {
        method: "GET",
        headers: headers,
      };

      const preferredLanguage = GetItem("PreferredLanguage");

      if (preferredLanguage != null) return preferredLanguage;

      const loadedPreferredLanguage = await fetch(
        `${api}/user/v1/GetPreferredLanguage?username=${username}`,
        options
      );

      const result = await loadedPreferredLanguage.json();
      StoreItem("PreferredLanguage", result);

      return result;
    });
  };

export const useSetPreferredLanguage = () => {
  const queryClient = useQueryClient();
  const username = useGetUsername();
  const tokenPromise = useGetToken();
  const api = useGetApi();

  return useMutation(
    async (language: string | null) => {
      const token = await tokenPromise;

      const headers = new Headers();
      headers.append("Authorization", token);

      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          Username: username,
          Language: language,
        }),
      };

      return fetch(`${api}/user/v1/SetPreferredLanguage`, options).then(() =>
        ChangeLanguage(language, username)
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("preferredLanguage");
      },
    }
  );
};

export const useGetSupportedLanguages = () => {
  const tokenPromise = useGetToken();
  const api = useGetApi();

  return useQuery("supportedLanguages", async () => {
    const token = await tokenPromise;

    const headers = new Headers();
    headers.append("Authorization", token);

    const options = {
      method: "GET",
      headers: headers,
    };

    const cache_languages = GetItem("SupportedLanguages");
    if (cache_languages != null) {
      return cache_languages;
    } else {
      const result = await fetch(
        `${api}/user/v1/GetSupportedLanguages`,
        options
      );
      const languages = await result.json();
      StoreItem("SupportedLanguages", languages);
      return languages;
    }
  });
};

const ChangeLanguage = (language: string | null, username: string | null) => {
  if (language === null && username === null) return;

  const preferredLanguage = GetItem("PreferredLanguage");

  if (preferredLanguage === null) return;

  preferredLanguage.language = language;
  preferredLanguage.username = username;
  StoreItem("PreferredLanguage", preferredLanguage);
};
