import { Stack } from "@mui/material";
import "../styles/SignIn.scss";
import { ScheduledMessage } from "./ScheduledMessage";
import { SignInButton } from "./SignInButton";

export const SignIn = () => {
  return (
    <Stack
      height={"100%"}
      justifyContent={"center"}
      alignItems="center"
      gap={4}
    >
      <ScheduledMessage uiLocation="login" />
      <SignInButton />
    </Stack>
  );
};
