import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { useGetIfVestasMail } from "../apis/authConfig";
import {
  useIsFavorite,
  useMarkDocumentAsFavorite,
  useUnmarkDocumentAsFavorite,
} from "../apis/favorites";
import { useB2CUserDocumentPermissions } from "../apis/userManagement";
import "../styles/MakeFavorite.scss";

interface Props {
  pdfInstance: any;
  DMSNumber: string;
  documentName: string;
  mode: string;
  documentUrl: string;
}

export const MakeFavorite = ({
  pdfInstance,
  DMSNumber,
  documentName,
  mode,
  documentUrl,
}: Props) => {
  const isVestasMail = useGetIfVestasMail();
  const { data: favorite } = useIsFavorite(DMSNumber);
  const { mutate: markAsFavorite } = useMarkDocumentAsFavorite(DMSNumber);
  const { mutate: unmarkAsFavorite } = useUnmarkDocumentAsFavorite(DMSNumber);

  const { status: b2cDocumentPermissionStatus } =
    useB2CUserDocumentPermissions();

  const FavoriteButton = () => {
    useEffect(() => {
      console.log(favorite);
    }, [favorite, b2cDocumentPermissionStatus]);

    function renderFavoriteButton() {
      if (favorite !== undefined) {
        if (favorite.isFavorite) {
          return (
            <Button
              variant="outlined"
              className="button-bar favorite-btn-size"
              onClick={() => {
                favorite.isFavorite ? unmarkAsFavorite() : markAsFavorite();
              }}
              startIcon={<StarRoundedIcon sx={{ color: "#EFCF27" }} />}
              sx={{
                "margin-top": "20px",
                "margin-right": "5px",
                "background-color": "#FFFFFF",
                color: "black",
                "border-color": "#FFFFFF",
              }}
            >
              Favorite
            </Button>
          );
        } else {
          return (
            <Button
              variant="outlined"
              className="button-bar favorite-btn-size"
              onClick={() => {
                favorite.IsFavorite ? unmarkAsFavorite() : markAsFavorite();
              }}
              startIcon={<StarBorderRoundedIcon />}
              sx={{
                "margin-top": "20px",
                "margin-right": "5px",
                "background-color": "#FFFFFF",
                color: "black",
                "border-color": "#FFFFFF",
              }}
            >
              Favorite
            </Button>
          );
        }
      }
    }

    return <React.Fragment>{renderFavoriteButton()}</React.Fragment>;
  };

  return mode !== "Special" ? (
    <div>
      <FavoriteButton />
    </div>
  ) : null;
};
