import { StoreItem, GetItem, DeleteItem } from "./cache";

export const AddError = (error: string) => {
  StoreItem("error", error);
};

export const RemoveError = () => {
  DeleteItem("error");
};

export const GetError = (): string => {
  return GetItem("error");
};
