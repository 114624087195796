import { Button, Stack, Typography } from "@mui/material";
import { useGetMessage } from "../apis/useGetMessage";

interface ScheduledMessageProps {
  uiLocation: "main" | "login";
}

export const ScheduledMessage = ({ uiLocation }: ScheduledMessageProps) => {
  const { data, isLoading } = useGetMessage();

  if (isLoading) return <></>;

  if (uiLocation === "main" && !data?.showAtMain) {
    return <></>;
  }

  if (uiLocation === "login" && !data?.showAtLogin) {
    return <></>;
  }

  return (
    <Stack gap={2} alignItems={"center"}>
      <Stack>
        {data?.header && (
          <Typography fontWeight={"bold"}>{data.header}</Typography>
        )}
        {data?.message && <Typography>{data.message}</Typography>}
      </Stack>
      {data?.targetUrl && data.targetText && (
        <Button variant="outlined" href={data.targetUrl} LinkComponent={Button}>
          {data.targetText}
        </Button>
      )}
    </Stack>
  );
};
