import { SlideshowOutlined } from "@mui/icons-material";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

export const VideoLink = () => {
  const theme = useTheme();
  const matchesThemeXs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Link
      to={"/videoPlayer/Vestas_Doc_App.mp4"}
      style={{
        textDecoration: "none",
        color: "lightgrey",
      }}
    >
      <Stack direction={"row"} gap={0.6} alignItems={"center"}>
        <SlideshowOutlined />
        {!matchesThemeXs && <Typography variant="button" color="white">
          MOBILE APP
        </Typography>}
      </Stack>
    </Link>
  );
};
