import { LogLevel } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import jwt_decode from "jwt-decode";

interface Token {
  exp: number;
  nbf: number;
  ver: string;
  iss: string;
  sub: string;
  aud: string;
  acr: string;
  email: string;
  nonce: string;
  iat: number;
  auth_time: number;
  name: string;
  given_name: string;
  family_name: string;
  idp_access_token: string;
  idp: string;
  tid: string;
  at_hash: string;
}

interface VestasAADToken {
  aud: string;
  iss: string;
  iat: number;
  nbf: number;
  exp: number;
  acct: number;
  acr: string;
  acrs: string[];
  aio: string;
  amr: string[];
  app_displayname: string;
  appid: string;
  appidacr: string;
  family_name: string;
  given_name: string;
  idtyp: string;
  ipaddr: string;
  name: string;
  oid: string;
  onprem_sid: string;
  platf: string;
  puid: string;
  rh: string;
  scp: string;
  signin_state: string[];
  sub: string;
  tenant_region_scope: string;
  tid: string;
  unique_name: string;
  upn: string;
  uti: string;
  ver: string;
}

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID || "",
    authority:
      process.env.REACT_APP_AUTH_AUTH +
        "/" +
        process.env.REACT_APP_AUTH_LOGIN_POLICY || "",
    knownAuthorities: [process.env.REACT_APP_AUTH_KNOWN_AUTHORITIES || ""],
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URL || "",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const useGetScopes = () => {
  return [process.env.REACT_APP_AUTH_SCOPE || ""];
};

export const useGetApi = () => {
  return [process.env.REACT_APP_API_B2C_BASE_URL];
};

export const useIsTestUser = () => {
  const email = useGetUsername();

  if (!email) {
    return false;
  }

  return email.toLowerCase() === "tst-nibae-01@vestas.com";
};

export const useGetUsername = () => {
  const { accounts } = useMsal();
  const isVestasMail = useGetIfVestasMail();
  if (accounts.length > 0) {
    const token = accounts[0].idTokenClaims;

    if (!token) {
      return "";
    }
    if (isVestasMail) {
      const decoded: VestasAADToken = jwt_decode(token.idp_access_token as string);
      return decoded.upn;
    } else {
      return token.email as string;
    }
  } else {
    return "";
  }
};

export const useGetIfVestasMail = (): boolean => {
  const { accounts } = useMsal();
  if (accounts.length > 0) {
    const token = accounts[0].idTokenClaims;
    return token?.idp !== null && token?.idp === "vestas.com";
  } else {
    return false;
  }
};

export const useGetToken = async () => {
  const { instance, accounts } = useMsal();
  const loginScopes = useGetScopes();
  const isVestasMail = useGetIfVestasMail();
  if (accounts.length > 0) {
    const tokenResponse = await instance.acquireTokenSilent({
      scopes: loginScopes,
      account: accounts[0],
    });

    const response = await tokenResponse;

    if (isVestasMail) {
      //const claims: Token = response.idTokenClaims as Token;
      //TODO Change this when B2B is ready
      // return `Bearer ${claims.idpToken}`;
      return `Bearer ${response.idToken}`;
    } else {
      return `Bearer ${response.idToken}`;
    }
  } else return "";
};

export const graphConfig = {
  graphMeEndpoint: process.env.REACT_APP_AUTH_B2B_GRAPH_ENDPOINT,
};

export const GetToken = async () => {
  return await useGetToken();
};
