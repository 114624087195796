import { useMutation } from "react-query";
import { useGetApi, useGetToken } from "./authConfig";

export const useExternalUserCheck = () => {
  const tokenPromise = useGetToken();
  const api = useGetApi();

  return useMutation("externalUserCheck", async () => {
    const token = await tokenPromise;

    const headers = new Headers();
    headers.append("Authorization", token);

    const options = {
      method: "GET",
      headers,
    };

    const result = await fetch(`${api}/file/v1/isexternaluser`, options);

    return await result.json();
  });
};
