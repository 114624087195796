import { Link } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import caretImg from "../images/caret-right.svg";
import "../styles/Videos.css";
import { LoadSpinner } from "./LoadSpinner";
import { useVideos } from "../apis/useVideos";
import "../styles/docList.scss";

export const Videos = () => {
  const { isLoading, data } = useVideos();

  const Videos = () => {
    if (data != null && data.length !== 0) {
      return (
        <ListGroup className="docsList">
          {data.map((video) => (
            <Row key={video.index}>
              <Col></Col>
              <Col xs={8}>
                <Link
                  to={`/videoPlayer/${video.fileName}`}
                  state={{ data: video }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListGroup.Item key={video.index} action>
                    {video.friendlyName}
                    <span className="float-right">
                      <img src={caretImg} style={{ verticalAlign: "middle" }} />
                    </span>
                  </ListGroup.Item>
                </Link>
              </Col>
              <Col></Col>
            </Row>
          ))}
        </ListGroup>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="App">
        <Videos />
      </div>
      {isLoading && <LoadSpinner />}
    </>
  );
};
