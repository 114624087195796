import { useGetToken, useGetApi, useGetUsername } from "./authConfig";
import { useQuery } from "react-query";

export const useSearch = (
  materialNo: string | null,
  serialNo: string | null
) => {
  const tokenPromise = useGetToken();
  const api = useGetApi();

  return useQuery(
    [`tools`, materialNo, serialNo],
    async () => {
      const token = await tokenPromise;

      const headers = new Headers();
      headers.append("Authorization", token);
      headers.append("access-control-allow-origin", "*");

      const options = {
        method: "GET",
        headers: headers,
      };

      let endpoint = api + "/certificates/v1/search";

      return fetch(
        endpoint + "?serialNo=" + serialNo + "&materialNo=" + materialNo,
        options
      ).then((res) => res.json());
    },
    {
      enabled:
        serialNo != null &&
        serialNo.length > 0 &&
        materialNo != null &&
        materialNo.length > 0,
    }
  );
};

export const GetCertificate = (url: string) => {
  const tokenPromise = useGetToken();
  const api = useGetApi();

  return useQuery([`certifcate`, url], async () => {
    const token = await tokenPromise;

    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("access-control-allow-origin", "*");

    const options = {
      method: "GET",
      headers: headers,
    };

    let endpoint = api + "/certificates/v1/getCertificate";
    return fetch(endpoint + "?url=" + url, options).then((data) => data.text());
  });
};
